//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;
$primary: #219cd7;
$second: #B8C9DA;
$third:  #1A4066;
$four: #253B50;
$brown_grey: #979797;
$fifth: #00A5F4;
$sixth: #97B2CE;
$yellow: #ffc107;