@import '@core/scss/core.scss';
@import './assets/scss/styles';
body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-weight: 400;
}
.datatable-header-cell {
    border-right: 1px solid rgba(34, 41, 47, 0.125);
    &:first-child {
        border-left: 1px solid rgba(34, 41, 47, 0.125);
    }
}

.datatable-body-cell {
    border-right: 1px solid rgba(34, 41, 47, 0.125);
    &:first-child {
        border-left: 1px solid rgba(34, 41, 47, 0.125);
    }
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
}